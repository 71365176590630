import React from "react";

export function AvatarEditor({ thumbnailMode, leftPanel, rightPanel, buttonTip, toolbar }) {
  return (
    <>
      <div className="main">
        {rightPanel}
        {buttonTip}
        {!thumbnailMode && leftPanel}
      </div>
    </>
  );
}
